import * as rawStockActionTypes from '../_actionTypes/rawStockActionTypes'

export const rawStock = (state= [], action ) => {
    switch(action.type){
        case rawStockActionTypes.GET:
            return state = action.rawStock
        case rawStockActionTypes.ADD:
            return state = action.rawStock
        case rawStockActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.rawStock._id);
            return  [
                ...state.slice(0, indx),
                action.rawStock,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}