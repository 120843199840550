import * as productStockActionTypes from '../_actionTypes/productStockActionTypes'

export const productStock = (state= [], action ) => {
    switch(action.type){
        case productStockActionTypes.GET:
            return state = action.productStock
        case productStockActionTypes.ADD:
            return state = action.productStock
        case productStockActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.productStock._id);
            return  [
                ...state.slice(0, indx),
                action.productStock,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}