import * as partnerActionTypes from '../_actionTypes/partnerActionTypes'

export const partners = ( state = [], action) => {
    switch(action.type){
        case partnerActionTypes.GET:
            return state = action.partners
        case partnerActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.partner._id);
            return  [
                ...state.slice(0, indx),
                action.partner,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}