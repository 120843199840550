import * as rawActionTypes from '../_actionTypes/rawActionTypes'

export const raws = (state = [], action) => {
    switch(action.type){
        case rawActionTypes.GET:
            return state = action.raws
        case rawActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.raw._id);
            return  [
                ...state.slice(0, indx),
                action.raw,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}