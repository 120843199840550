import * as operationActionTypes from '../_actionTypes/operationActionTypes'

export const operations = (state = [], action) => {
    switch(action.type){
        case operationActionTypes.GET:
            return state = action.operations
        case operationActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.operation._id);
            return  [
                ...state.slice(0, indx),
                action.operation,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}