import * as productionActionTypes from '../_actionTypes/productionActionTypes'

export const productions = (state = [], action) => {
    switch(action.type){
        case productionActionTypes.GET:
            return state = action.productions
        case productionActionTypes.DELETE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.production._id);
            return  [
                ...state.slice(0, indx),
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}