import * as utilActionTypes  from '../_actionTypes/utilActionTypes';

export const util = (state = {
  fields: [],
  services: [],
  cities: [],
  districts: [],
  events: []
}, action) => {
    switch(action.type){
      case utilActionTypes.FIELDS:
        return {
          ...state,
          fields: action.fields
        };
      case utilActionTypes.SERVICES:
        return {
          ...state,
          services: action.services
        };
      case utilActionTypes.CITIES:
        return{
          ...state,
          cities: action.cities
        };
      case utilActionTypes.DISTRICTS:
        return{
          ...state,
          districts: action.districts
        };
      case utilActionTypes.EVENTS:
        return{
          ...state,
          events: action.events
        };
      default:
        return state
    }
}