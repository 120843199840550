import * as productionOrderActionTypes from '../_actionTypes/productionOrderActionTypes'

export const productionOrders = (state = [], action) => {
    switch(action.type){
        case productionOrderActionTypes.CREATE:
            return state
        case productionOrderActionTypes.GETCURRENT:
            return state = action.orders
        case productionOrderActionTypes.GETCOMPLETED:
            return state = action.orders
        case productionOrderActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.order._id);
            return  [
                ...state.slice(0, indx),
                action.order,
                ...state.slice(indx + 1)
            ]
        default:
            return state
    }
}