import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import * as userActions from './_actions/userActions'
// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Yükleniyor...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  componentDidMount(){
    let token = this.props.cookies.cookies.token
    const { dispatch } = this.props
    if(token){
      dispatch(userActions.auth(token));
    }
  }
  render() {
    let loggedIn = this.props.loggedIn
    return (
      <Router >
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => { return loggedIn ?  <Redirect to='/'/> : <Login {...props}/>  } } />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={ props => { return  loggedIn ?  <DefaultLayout {...this.props} {...props} /> : <Redirect to='/login'/> }}/>
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return state.user
}
const ConnectedApp = connect(mapStateToProps)(App)

export default withCookies(ConnectedApp);
