import * as stockAdjustmentsActionTypes  from '../_actionTypes/stockAdjustmentsActionTypes';

let rawAdjustments = []
let productAdjustments = []

export const stockAdjustments = (state = {rawAdjustments, productAdjustments}, action) => {
    switch(action.type){
      case stockAdjustmentsActionTypes.CREATE_RAW_ADJ:
        rawAdjustments = state.rawAdjustments.concat(action.adjustment)
        return {
            ...state,
            rawAdjustments
        }
      case stockAdjustmentsActionTypes.CREATE_PRODUCT_ADJ:
        productAdjustments = state.productAdjustments.concat(action.adjustment)
        return {
            ...state,
            productAdjustments
        }
      case stockAdjustmentsActionTypes.GET_RAW_ADJ:
        return {
            ...state,
            rawAdjustments: action.adjustments
        }
      case stockAdjustmentsActionTypes.GET_PRODUCT_ADJ:
        return {
            ...state,
            productAdjustments: action.adjustments
        } 
      default:
        return state
    }
}