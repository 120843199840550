import * as staffActionTypes  from '../_actionTypes/staffActionTypes';

export const staff = (state = [], action) => {
    switch(action.type){
      case staffActionTypes.CREATE:
        return state
      case staffActionTypes.GETCURRENT:
        return state = action.staffs
      case staffActionTypes.GETPASSIVE:
        return state = action.staffs
      case staffActionTypes.UPDATE:
        const indx = state.map(function(e) { return e._id; }).indexOf(action.staff._id);
        return  [
            ...state.slice(0, indx),
            action.staff,
            ...state.slice(indx + 1)
            ] 
      default:
        return state
    }
}
