import * as localObjectsActionTypes from '../_actionTypes/localObjectsActionTypes'

let product = {  name : '', code : '', raws : [], operations : [], group : '', semiproducts : [] }
let semiproduct = {  name : '', code : '', raws : [], operations : [], group : '', semiproducts : [] }

export const localObjects = (state = { product, semiproduct }, action) => {
    switch(action.type){
        case localObjectsActionTypes.SEMIPRODUCT:
            return state = {
                ...state,
                semiproduct: action.object
            }
        case localObjectsActionTypes.PRODUCT:
            return state = {
                ...state,
                product: action.object
            }
        default:
            return state
    }
}