import { combineReducers } from 'redux';

import { alert } from './alertReducer';
import { user } from './userReducer';
import { util } from './utilReducer';
import { raws } from './rawReducer';
import { departments } from './departmentReducer';
import { operations } from './operationReducer';
import { products } from './productsReducer';
import { productions } from './productionReducer';
import { partners } from './partnerReducer';
import { orders } from './orderReducer'
import { rawStock } from './rawStockReducer'
import { productStock } from './productStockReducer'
import { dispatchNotes } from './dispatchNoteReducer'
import { rawTypes } from './rawTypeReducer'
import { productionReports } from './productionReportReducer'
import { localFilters } from './localFiltersReducer'
import { localObjects } from './localObjectsReducer'
import { invoicedDispatchNotes } from './invoicedDispatchNoteReducer'
import { vehicles } from './vehicleReducer'
import { productionOrders } from './productionOrderReducer'
import { staff } from './staffReducer'
import { stockAdjustments } from './stockAdjustmentsReducer'
 
export const rootReducer = combineReducers({
    alert,
    user,
    util,
    raws,
    departments,
    operations,
    products,
    productions,
    partners,
    orders,
    rawStock,
    productStock,
    dispatchNotes,
    rawTypes,
    productionReports,
    localFilters,
    localObjects,
    invoicedDispatchNotes,
    vehicles,
    productionOrders,
    staff,
    stockAdjustments
});