import * as orderActionTypes from '../_actionTypes/orderActionTypes'

export const orders = (state = [], action) => {
    switch(action.type){
        case orderActionTypes.GET:
            return state = action.orders
        case orderActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.order._id);
            return  [
                ...state.slice(0, indx),
                action.order,
                ...state.slice(indx + 1)
                ]
        case orderActionTypes.CLEAR:
            return state = []
        default:
            return state
    }
}