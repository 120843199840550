import * as rawTypeActionTypes from '../_actionTypes/rawTypeActionTypes'

export const rawTypes = (state = [], action) => {
    switch(action.type){
        case rawTypeActionTypes.GET:
            return state = action.rawTypes
        case rawTypeActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.rawType._id);
            return  [
                ...state.slice(0, indx),
                action.rawType,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}