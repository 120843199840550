import * as localFiltersActionTypes from '../_actionTypes/localFiltersActionTypes'

let production = ''
let product = ''
let productionOrder = {}

export const localFilters = (state = { production, product, productionOrder }, action) => {
    switch(action.type){
        case localFiltersActionTypes.PRODUCTION:
            return state = {
                ...state,
                production: action.filter
            }
        case localFiltersActionTypes.PRODUCT:
            return state = {
                ...state,
                product: action.filter
            }
        case localFiltersActionTypes.PRODUCTIONORDER:
            return state = {
                ...state,
                productionOrder: action.productionOrder
            }
        default:
            return state
    }
}