import * as productActionTypes from '../_actionTypes/productActionTypes'

export const products = (state = [], action) => {
    switch(action.type){
        case productActionTypes.GET:
            return state = action.products
        case productActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.product._id);
            return  [
                ...state.slice(0, indx),
                action.product,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}