import * as productionReportActionTypes from '../_actionTypes/productionReportActionTypes'

export const productionReports = (state = [], action) => {
    switch(action.type){
        case productionReportActionTypes.GET:
            return state = action.reports
        case productionReportActionTypes.CLEAR:
            return state = []
        case productionReportActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.report._id);
            if(action.report.reported === true){
                return  [
                    ...state.slice(0, indx),
                    ...state.slice(indx + 1)
                    ]
            }else{
                return  [
                    ...state.slice(0, indx),
                    action.report,
                    ...state.slice(indx + 1)
                    ]
            }
        default:
            return state
    }
}