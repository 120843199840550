import * as dispatchNoteActionTypes from '../_actionTypes/dispatchNoteActionTypes'

export const dispatchNotes = (state = [], action) => {
    switch(action.type){
        case dispatchNoteActionTypes.ADD:
            return state
        case dispatchNoteActionTypes.GET:
            return state = action.notes
        case dispatchNoteActionTypes.UPDATE:
            const indx = state.map(function(e) { return e._id; }).indexOf(action.note._id);
            return  [
                ...state.slice(0, indx),
                action.note,
                ...state.slice(indx + 1)
                ]
        default:
            return state
    }
}